// BREAKPOINTS
$mobile: 780px;
$tablet: 1024px;

$charcoal-grey: #2a2f35;
$powered-by-grey: #8a8c90;

$blue: #33a2c1;
$hover-blue: #187d9c;

$accessibility-blue: #007BA8;
$accessibility-focus-blue: #0C47C0;

$pale-blue: rgba($blue, 0.40);
$hover-pale-blue: rgba($blue, 0.50);

$accessibility-blue: #007BA8;
$accessibility-focus-blue: #0C47C0;

$bright-blue: #19b6f3;
$hover-bright-blue: #0d9bd2;

$blue-black: #2a2f35;
$hover-blue-black: darken($blue-black, 10);

$red: #e21039;
$hover-red: #b40f31;
$error-banner-red: #FEF5F7;

$green: #8bbb39;
$hover-green: #729932;

// GREYSCALE
$white: #ffffff;
$off-white: #f5f7f9;
$disabled-grey: #c5c8ca; // only used for disabled items
$light-grey: #dfe0e1;
$grey: #8a8c90;
$medium-grey: #666666;
$dark-grey: #2a2e35;
$black: #010202; // only used for background of fullscreen

// Hover greyscale
$hover-white: darken($white, 10);
$hover-off-white: #edf1f4;
$hover-light-grey: darken($light-grey, 10);
$hover-grey: darken($grey, 10);
$hover-dark-grey: darken($dark-grey, 10);
$hover-disabled-grey: darken($disabled-grey, 10);
$hover-black: darken($black, 10);

//Social Colours
$twitter-blue: #2CA9E1;
$instagram-pink: #e53c5f;
$facebook-blue: #3B5999;

$hover-twitter-blue: darken($twitter-blue, 10);
$hover-instagram-pink: darken($instagram-pink, 10);
$hover-facebook-blue: darken($facebook-blue, 10);

// fonts
$base-font: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$display-font: 'Source Sans Pro', Helvetica, Arial, sans-serif;
// Box shadow
$shadow-base: 0 0 40px 0 rgba(0, 0, 0, 0.12);

// z-index
$z-index-none: 0;
$z-index-bottom: 1;
$z-index-middle: 2;
$z-index-top: 3;
$z-index-overtake: 4;

// DONT USE THESE UNLESS THE WORLD IS ENDING
$z-index-overtake-1: 5;
$z-index-overtake-2: 6;
$z-index-overtake-3: 7;
$z-index-overtake-4: 8;
$z-index-overtake-5: 9;

$num-grid-columns: 12;
$grid-gutter-width: 10px;
$max-brand-logo-height: 96px;

@mixin focusState($offset) {
    outline: 2px solid $accessibility-focus-blue;
    outline-offset: unquote($offset);
    outline-style: auto;
}