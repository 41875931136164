@import '../styles/variables.scss';

.donePage {
  height: 75vh;

  .contentContainer {
    margin: 0;
    position: absolute;
    top: 45%; // vertical center-alignment adjusted slightly
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}

// mobile
@media only screen and (max-width: $mobile) {
  .donePage {
    height: auto;
      .contentContainer {
        padding-bottom: 5px;
        text-align: center;
        margin: 72px 16px 0 16px;
        position: relative;
        transform: none;
      }
      .brandLogoContainer {
        &__brandLogo {
          margin: 0 auto;
        }
    }
  }
}
