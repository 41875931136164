.auth-portal__not-business {
  button {
    width: 310px;
    margin: 10px auto;
  }

  .title-component {
    padding: 0 40px;
  }

  .subtitle-component {
    margin: 20px
  }
}