@import './variables';

html {
    box-sizing: border-box;
}

// stylelint-disable
*, 
*:before,
*:after {
    box-sizing: inherit;
}
// stylelint-enable

form input::-webkit-validation-bubble-message,
form select::-webkit-validation-bubble-message,
form textarea::-webkit-validation-bubble-message {
    display: none;
}

input[type='text'],
input[type='email'],
input[type='password'],
input[type='number'] {
    width: 100%;

    &:focus {
        outline: none;
    }
}
/* stylelint-disable */
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
/* stylelint-enable */
input::-ms-clear {
    width: 0;
    height: 0;
}

img {
    display: block;
    max-width: 100%;
}

iframe {
    display: block;
    width: 100%;
    border: 0;
}

ul {
    margin: 0;
    padding-left: 0;
    list-style: none;
}

fieldset {
    margin: 0;
    padding: 32px 0 0;
    border: none;
}

button {
    border: none;
    background-color: inherit;
    color: inherit;
}

textarea {
    position: relative;

    width: 100%;
    min-width: 200px;
    margin-top: 8px;
    padding: 8px 15px;

    border: 1px solid $grey;
    border-radius: 4px;

    color: $dark-grey;
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;

    resize: none;
    
    &.pad-right {
        padding-right: 78px;
    }

    &:focus {
        border: 1px solid $blue;
        outline: none;
    }
}
