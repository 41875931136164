@import '../styles/variables.scss';

.connectAccounts {
    font-family: $display-font;
    &__accountList {
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
        margin-top: 32px;

        &--title {
            text-align: left;
            color: $medium-grey;
            text-transform: uppercase;
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 18px;
            display: flex;
            align-items: center;
            margin-bottom: 8px;
        }

        &--icon {
            margin-right: 4px;
        }

        &--fullWidth {
            flex-basis: 100%;
        }

        &__content {
            overflow-y: auto;
            max-height: 120px;
        }

        .checkbox__label {
            color: $charcoal-grey;
            font-size: 14px;
            line-height: 20px;
            font-weight: 400;
        }

        .checkbox {
            margin-top: 4px;
        }
    }

    &__callToActionContainer {
        margin-top: 32px;
    }
}

.logoTitleContainer {
    font-family: $display-font;

    &__title {
        margin: 16px 0;
        font-size: 40px;
        font-weight: 700;
        color: $charcoal-grey;
        line-height: 36px;
    }

    &__description {
        color: $charcoal-grey;
        font-size: 16px;
        line-height: 24px;
    }
}


.faq {
    &__expansionPanel {
        margin: 32px 0;
        font-family: $display-font;
        p{
            font-weight: 400;
        }

        a {
            text-decoration: underline;
        }

        &--titleType {
            font-weight: bold;
            font-weight: 700;
        }
    }
}

.permissions {
    &__expansionPanel {
        margin: 24px 0 0;

        ul {
            list-style-position: outside;
            margin-left: 15px;
        }
    }
}

.retryConnecting {
    margin-bottom: 56px;
    margin-top: 32px;
    font-weight: bold;
    &:focus {
      @include focusState('3px');
    }
  }

@media only screen and (max-width: $mobile) {
    .logoTitleContainer{
        &__title {
            font-size: 32px;
        }
    }
  }

  @media only screen and (max-width: $tablet) {
    .faq {
        &__expansionPanel {
            margin-bottom: 0;
        }
    }
  }