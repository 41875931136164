@import '../../styles/variables';
.image-component {
    display: flex;
    align-items: center;
    max-width: 100%;
    margin: auto;
    img { 
        max-width: 100%;
        display: block;
    }
}