@import '../styles/app.scss';

.sidePanel {
  height: 100%;

  &__poweredBy {
    margin-right: -8px;
    display: flex;
    align-items: center;
    flex-shrink:1;
    font-size: 12px;
    color: $powered-by-grey;
    justify-content: flex-end;
    img {
      width: 150px;
    }
  }

  &__authPreview {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
  }

  .cr__gallery .loadMore {
    z-index: $z-index-middle;
  }
}
