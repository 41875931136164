@import '../../styles/variables.scss';

.benefit {
  margin: 16px 0;
  display: flex;

  &__icon {
    margin-right: 8px;
    display: inline;
    height: 24px;
  }

  p {
    display: inline;
    line-height: 24px;
    margin: 0px;
  }
}

.callToActionContainer {
  margin-bottom: 56px;
  margin-top: 32px;
}

.faq {
  margin-top: 48px;

  &__expansionPanel {
    margin: 24px 0;
  }
}

// mobile
@media only screen and (max-width: $mobile) {
  .benefit__icon {
    margin-right: 16px;
  }

  .callToAction {
    display: none;
  }
}