@import '../../styles/app.scss';

.CTA {
  &__callToAction {
    font-weight: bold;
    &:focus {
      @include focusState('3px');
    }
    &:hover {
      opacity: 0.6,
    }
  }

  &__bottomBar {
    grid-column: 2/12;
    background-color: white;
    display: none;
    position: fixed;
    left: 0;
    bottom: 0;
    min-width: calc(100vw + 10px);
    z-index: 5;

    margin-left: -5px;
    padding: 16px 26px;

    -webkit-box-shadow: 0px -4px 10px -9px #000000;
    box-shadow: 0px -4px 10px -9px #000000;

    .BottomBarCTA {
      width: 100%;
      justify-content: center;
      font-weight: bold;

      &:focus {
        @include focusState('3px');
      }
    }
  }

  // mobile
  @media only screen and (max-width: $mobile) {
    &__callToAction {
      display: none;
    }

    &__bottomBar {
      display: block;
    }
  }
}
