@import '../../styles//variables.scss';

.modalWrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(42,47,53,.85);
  padding: 32px 24px;
  z-index: $z-index-overtake-1;
}

.modal {
  max-height: calc(100vh - 62px);
  max-width: 720px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  padding: 60px 50px;
  background-color: $white;
  overflow: auto;
  position: relative;

  &__close {
    right: 30px;
    top: 25px;
    position: absolute;
    overflow-y: scroll;
    overflow-x: hidden;

    .iconWrapper {
      position: fixed;
    }
  }
}