@import '../styles/app.scss';

.page-layout {
  background-color: white;
  display: grid;
  grid-template-columns: repeat($num-grid-columns, 1fr);
  grid-auto-rows: minmax(100px, auto);
  grid-gap: $grid-gutter-width;
  min-width: 100%;
  margin: 0;
  min-height: 100vh;
  font-family: $display-font;

  &__content {
    padding-top: 40px;
    padding-bottom: 30px;
    overflow-y: auto;
    max-height: 100vh;
    grid-column: 2/7;


    // Added because the focus state of the call to action was being cut off to the left
    // and setting overflow: visible was affect the page scroll functionality
    padding-left: 5px;
    margin-left: -5px;

    // Places the scrollbar in the center of the gap between the main content and the gallery
    // The equation is: ((width of window - grid gutters) / # of grid columns) / 2
    // is it ugly? Yes, does it work? Yes, can I think of a better solution? No
    padding-right: calc(((100vw - ((#{$num-grid-columns - 1}) * #{$grid-gutter-width})) / #{$num-grid-columns}) / 2);
    margin-right: calc(((100vw - ((#{$num-grid-columns - 1}) * #{$grid-gutter-width})) / #{$num-grid-columns}) / 2 - #{$grid-gutter-width});
  }

  &__sidePanel {
    background-color: white;
    padding: 0 10px;
    max-height: 100vh;
    overflow-y: auto;
    grid-column: 7/13;
  }
  .brandLogoContainer {
    min-height: 0;
    max-height: $max-brand-logo-height;
    max-width: 75%;

    &__brandLogo {
      max-height: $max-brand-logo-height;
    }
  }
}

// tablet
@media only screen and (max-width: $tablet) {
  .page-layout {
    padding: 56px;

    &__content {
      grid-column: 1/13;

      overflow-y: unset;
      max-height: unset;
      padding-right: 0px;
      margin-right: 0px;
    }

    &__sidePanel {
      padding: 0px;
      overflow-y: unset;
      grid-column: 1/13;
    }

    .brandLogoContainer {
      max-width: 50%;
    }
  }
}

// mobile
@media only screen and (max-width: $mobile) {
  .page-layout {
    padding: 16px;
    padding-bottom: 80px;

    &__content {
      grid-column: 1/13;
      padding-top: 0px;
    }

    &__sidePanel {
      margin: 0;
      grid-column: 1/13;
    }

    .brandLogoContainer {
      max-width: 50%;
    }
  }
}
