.person {
    color: #33a2c1;
    display: flex;
    align-items: center;
    .profile-photo {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        margin-right: 8px;
        white-space: nowrap;
    }
}