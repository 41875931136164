.attached-button-component {
    margin: 24px -33px -25px -33px;

    button {
        width: 100%;
        padding: 16px 0;
        justify-content: center;
        border-radius: 0 0 6px 6px;
        padding: 16px;
        font-weight: 700;
    }
}