@import '../styles/variables.scss';

.emailPage {
  &__checkbox {
    margin-top: 16px;

    .checkbox__label {
      font-weight: 400;
    }
  }

  &__promptContainer {
    margin: 32px 0;

    .inputContainer__label {
      font-weight: bold;
      color: $medium-grey;
    }

    .inputContainer__input {
      &--has-value {
        font-size: 16px;
        font-weight: 400;
      }
    }

    .required {
      color: $medium-grey;
    }
  }

  &__requiredLabel {
    float: right;
    color: $medium-grey;
  }

  &__callToActionContainer {
    margin-top: 32px;
  }

  // mobile
  @media only screen and (max-width: $mobile) {
    &__callToActionContainer {
      margin-top: 0;
    }
  }
}
