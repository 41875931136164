@import '../../styles/app.scss';

.errorBanner {
  background-color: $error-banner-red;
  padding: 16px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  height: 56px;
  border-radius: 4px;

  p {
    color: $hover-red;
    font-weight: bold;
    font-size: 16px;
    margin: 0;
  }
}