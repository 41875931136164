@import './variables';

body {
    font-family: $base-font;
}

.title-component  {
    font-family: $display-font;
}

.title {
    margin: 16px 0;
    font-size: 40px;
}

.description {
    margin-bottom: 32px;
}

a {
    color: $blue;
    text-decoration: none;

    &:hover {
        color: $hover-blue;
    }
}

p {
    margin: 0 0 8px 0;
    color: $charcoal-grey;
    font-size: 16px;
    line-height: 24px;
    font-weight: normal;

    a {
        color: $blue;
        font-weight: 400;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
}

// mobile
@media only screen and (max-width: $mobile) {
    .title {
        font-size: 32px;
    }
}