@import '../../styles/variables';

$card-side-padding: 32px;

.card-component {
    background: $white;
    border-radius: 6px;
    padding: 24px $card-side-padding;
    text-align: center;
    margin-bottom: 16px;
    width: 100%;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    box-shadow: $shadow-base;
    border: 1px solid $light-grey;

    &--full-width {
        margin: 0 (-$card-side-padding);
    }
}