@import '../../styles/variables.scss';

.loadingSpinner {
  display: flex;
  position: fixed;

  top: 0;
  left: 0;

  z-index: $z-index-overtake-2;

  align-items: center;
  justify-content: center;

  width: 100vw;
  height: 100vh;

  background-color: $white;
  &__statusLink {
      position: absolute;
      bottom: 25%;
      a {
          padding: 0 3px;
          color: $blue;
      }
      a:hover,
      a:focus {
          text-decoration: underline;
      }
  }
}