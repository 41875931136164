body {
    background-color: $off-white;
    color: $charcoal-grey;
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
html, body, #root, #root>div {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }

.app {
    display: flex;
    flex-direction: column;
    padding-top: 16px;
    justify-content: center;
    flex-grow: 1;

    .auth-portal {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}
