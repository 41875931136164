.gifficon {
    &--small {
        height: 150px;
    }
    &--medium {
        height: 175px;
    }

    &--centered {
        margin: auto;
    }
}
