@import '../../styles/variables';

.messagePanel {
  margin-top: 24px;
  padding: 8px;
  text-align: left;
  line-height: 20px;
  font-size: 14px;

  p {
    font-size: inherit;
    color: inherit;
    line-height: inherit;
    font-weight: inherit;
  }

  h3 {
    font-size: inherit;
    font-weight: 800;
    margin: 0 0 8px;
  }

  &--default {
    color: $dark-grey;
    background-color: rgba($medium-grey, 0.05);
  }

  &--error {
    font-size: 16px;
    line-height: 24px;
    color: $red;
    background-color: rgba($red, 0.05);
  }
}